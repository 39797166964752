.address {
  &_div {
    padding-top: 20px;
  }
  &_detail {
    margin-bottom: 10px;
    p {
      display: inline-block;
      margin-bottom: 0px;
      &:first-child {
        color: $default_color;
        display: inline-block;
        width: 150px;
      }
    }
  }
  &_item {
    border: 1px solid rgb(236, 236, 236);
    border-radius: 30px;
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 300px;
    position: relative;
    &:hover {
      .address {
        &_content {
          height: 20% !important;
          /* margin-top: -15px; */
        }
      }
      iframe {
        height: 260px;
      }
    }
  }
  &_title {
    font-weight: 700;
  }
  &_content {
    background-color: $white;
    padding: 15px 30px;
    height: 80%;
    transition: 0.5s ease all;
  }
  iframe {
    height: 20%;
  }
}
