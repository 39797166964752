.nav {
  &-tabs {
    width: 100%;
    text-align: center;
    display: inline-block;
    border: 0;
    .nav {
      &-item {
        display: inline-block;
      }
      &-link {
        /*  border: 1px solid $default_color; */
        color: $default_color;
        font-weight: 600;
        border-radius: 100px;
        margin: 0 15px;
        padding: 10px 30px;
        &.active {
          border: 1px solid $default_color;
          background-color: $default_color;
          color: $white;
        }
      }
    }
  }
}

.card-news-img {
  img {
    width: 100%;
  }
}

.slider-main {
  .btn {
    border: 2px solid $white;
  }
}

@media screen and (max-width: 767px) {
  .header-mobile {
    .btn {
      color: $white;
      width: 84%;
      display: inline-block;
      margin-bottom: 11px;
    }
  }
}
