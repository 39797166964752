$white: #fff !default;
$gray-100: #c7c7c7 !default;
$gray-200: #7a7a7a !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$blue-100: #0060a9 !default;
$black: #000 !default;
$dark: #381c17 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$blue: #002154 !default;
$dark-blue: #1b3b57 !default;
$indigo: #000711 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// Browns

$brown: #755141 !default;
$brown-100: #bc5930 !default;
$brown-200: #966753 !default;
$brown-300: #d0ae93 !default;
$brown-400: #002154 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary: $blue !default;
$secondary: $brown-300 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

//Social
$facebook: #3b5998;
$twitter: #1da1f2;
$instagram: #c32aa3;
$linkedin: #007bb5;
$whatsapp: #25d366;
$youtube: #ff0000;
$pinterest: #bd081c;

//Default Colors
$default_color: #0033a1;
$dark_blue: #111827;
$default_blue_color: #1bbed5;
$default_pink_color: #ef2d73;
$light_gray: #f3f4f6;
$text_gray: #111827;
$text_hover_gray: #111827;

.gray {
  &-200 {
    background-color: $gray-200;
  }
}
