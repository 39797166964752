.section {
  padding: 2rem 0;
  @media screen and (max-width: 767px) {
    padding: 1rem 0;
    padding-bottom: 0;
  }
  &_news {
    background-color: lighten($color: $default_color, $amount: 70);
  }
  &_header {
    text-align: center;
    position: relative;
    margin-bottom: 0px;
    background-color: #f1f0f0;
    padding: 10px 0;
    &_page {
      background-color: $white;
      padding: 0px 0 20px 0;
      position: relative;
      background-size: cover;

      &.blog {
        margin-top: 69px;
      }
      &_img {
        width: 100%;
        height: 294px;
        position: relative;
        overflow: hidden;
        span {
          position: absolute !important;
          width: 100% !important;
          height: 100% !important;
          width: 100% !important;
          top: 50% !important;
          left: 50% !important;
          transform: translate(-50%, -50%);
        }
        img {
          height: auto !important;
          max-height: initial !important;
        }
      }
      &_title {
        margin: 0;
        width: 100%;
        text-align: center;
      }
      h1 {
        color: #0033a1;
      }
    }
  }
  &_title {
    font-size: 1.5rem;
    color: $default_color;
    font-weight: 700;
  }
  &_sm {
    border-radius: 1.5rem;
    padding: 0;
    width: 100%;
    &_bg {
      background-color: rgba($color: $black, $alpha: 0.03);
      border-radius: 10px;
    }
    &_header {
      display: inline-block;
      width: 100%;
      padding: 1rem;
      //border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
    }
    &_title {
      font-weight: 700;
      margin: 0;
      text-align: center;
    }
  }
  &_suggestion {
    background: linear-gradient(#1bbed5, $default_color);
    margin-top: 50px;
    position: relative;
  }
  &_features {
    padding: 1rem 0;
  }
}

@include desktop() {
  .section {
    &_title {
      font-size: 24px;
    }
  }
}
