.btn {
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  //box-shadow: 0 0.5rem 0.5rem 0 rgba($color: $black, $alpha: 0.3);
  transition: 0.5s ease all;
  display: inline-block;
  font-weight: 700;
  &-default {
    color: $white;
    background-color: $default_color;
    &:hover {
      color: $white;
      background-color: darken($color: $default_color, $amount: 10);
    }
    &-1 {
      color: $white;
      background-color: darken($color: $default_color, $amount: 13);
      &:hover {
        background-color: darken($color: $default_color, $amount: 10);
      }
    }
  }
  &-primary {
    color: $white;
    background-color: $primary;
    &:hover {
      background-color: darken($color: $primary, $amount: 10);
    }
  }
  &-secondary {
    color: $white;
    background-color: $default_pink_color;
    border-color: transparent !important;
    &:hover {
      background-color: darken($color: $default_pink_color, $amount: 10);
    }
  }
  &-gray {
    &-1 {
      color: $black;
      background-color: $gray-100;
      &:hover {
        background-color: darken($color: $gray-100, $amount: 10);
      }
    }
    &-2 {
      color: $black;
      background-color: $gray-200;
      &:hover {
        background-color: darken($color: $gray-200, $amount: 10);
      }
    }
    &-3 {
      color: $black;
      background-color: $gray-300;
      &:hover {
        background-color: darken($color: $gray-300, $amount: 10);
      }
    }
    &-4 {
      color: $black;
      background-color: $gray-400;
      &:hover {
        background-color: darken($color: $gray-400, $amount: 10);
      }
    }
    &-5 {
      color: $black;
      background-color: $gray-500;
      &:hover {
        background-color: darken($color: $gray-500, $amount: 10);
      }
    }
    &-6 {
      color: $white;
      background-color: $gray-600;
      &:hover {
        background-color: darken($color: $gray-600, $amount: 10);
      }
    }
    &-7 {
      color: $white;
      background-color: $gray-700;
      &:hover {
        background-color: darken($color: $gray-700, $amount: 10);
      }
    }
    &-8 {
      color: $white;
      background-color: $gray-800;
      &:hover {
        background-color: darken($color: $gray-800, $amount: 10);
      }
    }
    &-9 {
      color: $white;
      background-color: $gray-900;
      &:hover {
        background-color: darken($color: $gray-900, $amount: 10);
      }
    }
  }
  &-outline {
    &-default {
      background-color: $white;
      border: 1px solid $default_color;
      color: $default_color;
      &:hover {
        color: $white;
        background-color: $default_color;
      }
    }
  }
  a {
    color: $white !important;
  }
  &-with-link {
    position: relative;
    /* width: 100%; */
    padding: 16px 64px;
    margin-top: 40px;
    div {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    h2 {
      font-size: 18px !important;
      margin: 0 !important;
    }
  }
  &-prev {
    width: 100%;
    height: 50px;
    border: 1px solid $default_color;
    background-color: transparent;
    color: $default_color;
    &:hover {
      background-color: $default_color;
    }
  }
  &-next {
    background-color: $success;
    width: 100%;
    height: 50px;
    border-color: $success;
  }
}

.dropdown-copy button {
  background: none;
  border: 0;
  color: #000;
  padding: 0 5px;
  font-size: 21px;
}

.dropdown-copy button:after {
  display: none;
}

.dropdown-copy button:focus-visible {
  border: 0 !important;
  outline: -webkit-focus-ring-color auto 0px;
}

.dropdown-copy .dropdown-menu i {
  margin-right: 12px;
  color: #0033a1;
}

.dropdown-copy .dropdown-menu {
  background: #fbfbfb;
  border: 0;
  box-shadow: 0px 0px 17px 0px #00000047;
}

.btn {
  &-sector {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid #e7e7e7;
    padding: 10px 10px 10px 51px;
    height: 94px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      position: absolute;
      top: 50%;
      left: 13px;
      height: 25px;
      width: 25px;
      background-color: #eee;
      border-radius: 50%;
      transform: translate(0, -50%) rotate(45deg);
      &:after {
        content: "";
        background: white;
        position: absolute;
        width: 2px;
        height: 11px;
        left: 12px;
        top: 6px;
      }
      &:before {
        content: "";
        position: absolute;
        width: 6px;
        height: 2px;
        background-color: white;
        left: 8px;
        top: 15px;
      }
    }
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
    input:checked ~ .checkmark {
      background-color: $default_color;
      &:after,
      &:before {
        display: block;
      }
    }
  }
}
