.form {
  &_container {
    padding: 50px 22px;
  }
  &_group {
    margin-bottom: 15px;
    width: 100%;
  }
  &_control {
    label {
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      display: inline-block;
      margin-bottom: 10px;
    }
    input {
      background-color: rgba($color: $gray-100, $alpha: 0.2);
      width: 100%;
      height: 50px;
      padding: 0 15px;
      border: 0;
    }
    textarea {
      background-color: rgba($color: $black, $alpha: 0.05);
      width: 100%;
      padding: 15px 15px;
    }
  }
  &_newsletter {
    padding: 15px;
    background-color: #eeeeee;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    h4 {
      color: $black;
      font-weight: 600;
    }
  }
}

.error {
  color: $danger;
  font-size: 11px;
  &_input {
    border: 1px solid $danger;
  }
}

.error {
  &-input {
    border: 1px solid $danger;
  }
}

.form {
  &-footer {
    input {
      color: $black !important;
      background-color: rgba(199, 199, 199, 0.2) !important;
      &:focus {
        background-color: rgba($color: $white, $alpha: 0.4) !important;
      }
      &::placeholder {
        color: $black !important;
      }
    }
  }
}

input[type="radio"] {
  margin-right: 5px;
}
.input-radio {
  display: inline-block;
  margin-right: 30px;
}

.styled-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.styled-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 100%;
}

/* On mouse-over, add a grey background color */
.styled-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.styled-radio input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,
.checkmark::before {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.styled-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.styled-radio .checkmark:after {
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  /* border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); */
  background: #fff;
  border-radius: 100%;
}

.form-input input {
  background: #f0f0f0;
  border: 0;
  height: 49px;
  width: 100%;
}

.form-input {
  margin-bottom: 15px;
  &.error-form {
    label {
      color: #ff0404;
    }
    input {
      border: 1px solid red;
    }
  }
}

.error {
  color: #ff0404;
  font-size: 13px;
}
