.notfound {
  margin-top: 100px;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 100px;
  h1 {
    font-size: 1500%;
    color: $cyan;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 767px) {
      font-size: 1020%;
    }
  }
  &_div {
    position: relative;
  }
  &_back {
    font-size: 1500% !important;
    position: absolute !important;
    top: 47%;
    left: 49%;
    transform: translate(-50%, -50%);
    color: rgba($color: $black, $alpha: 0.1) !important;
    z-index: 1 !important;
    @media screen and (max-width: 767px) {
      font-size: 1034% !important;
    }
  }
  p {
    font-size: 100%;
  }
}
