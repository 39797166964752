.footer {
  background: #dedede;
  padding-top: 30px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  &_phone {
    margin-right: 1rem;
    background: $default_color;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    min-width: 300px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    max-width: 252px;
    height: 90px;
    &_title {
      color: $white;
      margin: 0;
    }
    &_number {
      color: $white;
      line-height: 1;
      font-size: 1.5rem;
      font-weight: 600;
    }
    i {
      position: absolute;
      font-size: 7rem;
      color: rgba($color: $white, $alpha: 0.2);
      bottom: -27px;
      right: -16px;
      transform: rotate(-45deg);
    }
  }
  &_logo {
    margin-bottom: 30px;
    img {
      height: 38px;
    }
  }
  &_bottom {
    padding: 1rem 0;
    border-top: 1px solid rgba($color: $white, $alpha: 0.1);
    @media screen and (max-width: 767px) {
      ul {
        margin-top: 25px;
      }
    }
  }
  &_menu {
    &_item {
    }
    &_link {
      color: $black;
    }
  }
  &_bot {
    background-color: darken($color: $default_color, $amount: 2);
    padding: 15px 0;
    color: $white;
    @media screen and (max-width: 767px) {
      text-align: center;
      .justify-content-between {
        display: inline-block !important;
        justify-content: center !important;
      }
      p {
        text-align: center !important;
      }
    }
  }
  &_newsletter {
    width: 100%;
    h4 {
      color: $white;
    }
    input {
      color: $white;
    }
    button {
      padding: 5px 15px;
      border-radius: 5px;
    }
  }
  &_menu_links {
    padding: 0;
    list-style-type: none;
    li {
      list-style-type: none;
      margin-bottom: 10px;
      width: 49%;
      display: inline-block;
      @media screen and (max-width: 767px) {
        width: 100%;
        display: block;
      }
      a {
        cursor: pointer;
        transition: 0.5s ease all;
        position: relative;
        color: $default_color;
        font-weight: 600;
        i {
          opacity: 0;
          transition: 0.5s ease all;
          position: absolute;
          left: -15px;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover {
          padding-left: 15px;
          i {
            opacity: 1;
            left: 0;
          }
        }
      }
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      list-style-type: none;
      margin-bottom: 10px;
    }
  }
}

.social {
  &_media {
    &_list {
      &_item {
        display: inline-block;
        margin: 0;
      }
      &_link {
        padding: 10px 0;
        color: $default_color;
        background-color: $white;
        margin: 0 0 0 10px;
        border-radius: 5px;
        width: 50px;
        text-align: center;
        display: inline-block;
        transition: 0.5s ease all;
        &:hover {
          background-color: $default_color;
          color: $white;
        }
      }
    }
  }
}

@include desktop() {
  .footer {
    &_phone {
      width: 100%;
    }
    &_logo {
      img {
        height: 50px;
      }
    }
  }
}
