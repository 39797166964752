.slider {
  &_main {
    height: 80vh;
    padding-bottom: 40px !important;
    @media screen and (max-width: 767px) {
      height: 622px;
    }
    .slider {
      &_inner {
        position: absolute;
        bottom: 0;
        padding: 25px;
        background: transparent;
        width: 100%;
        height: 27vh;
        @media screen and (max-width: 767px) {
          height: 28vh;
        }
      }
    }
    .swiper {
      &-slide {
        width: 1230px !important;
      }
      &-pagination {
        &-bullets {
          bottom: 5px;
        }
        &-bullet {
          &-active {
          }
        }
      }
    }
  }
  &_bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 767px) {
      background-size: 100%;
      background-position: top;
    }
  }
  &_inner {
    background-color: darken($color: $default_color, $amount: 5);
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(
        $color: darken($color: $dark_blue, $amount: 5),
        $alpha: 0.7
      );
    }
  }
  &_title,
  &_description {
    color: $white;
  }
  &_title {
    line-height: 1.5;
    font-weight: 700;
    font-size: 4vh;
    @media screen and (max-width: 767px) {
      font-size: 20px !important;
    }
  }
  &_link {
    min-width: 200px;
    position: relative;
    padding: 1rem 2rem;
    background-color: $blue-100;
    text-align: center;
    i {
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%) rotate(360deg);
      width: 25px;
      height: 25px;
      border-radius: 100px;
      text-align: center;
      padding: 3px 0;
      transition: 0.5s ease all;
    }
    &:hover {
      i {
        color: $white;
        background-color: $primary;
        border-radius: 100px;
        text-align: center;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &_news {
    overflow: hidden;
    border-radius: 0.5rem;
    display: inline-block;
    margin-bottom: 4rem;
    width: 100%;
    padding: 17px;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    &_img {
      height: 200px;
      overflow: hidden;
      position: relative;
      //border-bottom:3px solid $gray-700;
      img {
        max-width: 100%;
        min-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.5s ease all;
        object-fit: cover;
      }
    }
    &_body {
      padding: 1.5rem;
      position: relative;
      transition: 0.5s ease all;
    }
    &_category {
      background-color: $gray-700;
      color: $white;
      font-weight: 600;
      padding: 0.3rem 1rem;
      border-radius: 100px;
      float: left;
      margin-top: -42px;
    }
    &_title {
      color: $text_gray;
      font-weight: bold;
      height: 88px;
      overflow: hidden;
      font-size: calc(1rem + 0.2vw);
    }
    &_desc {
    }
    &_category {
    }
    &:hover {
      .slider {
        &_news {
          &_img {
            img {
              transform: translate(-50%, -50%) scale(1.2);
            }
          }
          &_title {
            color: $default_color;
          }
        }
      }
    }
  }
  &_brand {
    padding: 0rem 15px;
    padding-bottom: 13px;
    &_link {
      height: 100px;
      background-color: $white;
      display: inline-block;
      border-radius: 1.5rem;
      overflow: hidden;
      position: relative;
      width: 100%;
      margin: 15px 0;
      border: 1px solid rgba($color: $black, $alpha: 0.1);
      img {
        position: absolute !important;
        width: auto !important;
        max-width: 80% !important;
        min-width: inherit !important;
        max-height: 80% !important;
        min-height: inherit !important;
        height: auto !important;
      }
      &:hover {
        .slider_brand_img {
          mix-blend-mode: normal;
        }
      }
    }
    &_img {
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
      max-width: 70%;
      max-height: 70%;
      mix-blend-mode: luminosity;
    }
    .swiper-pagination {
      bottom: 20px;
    }
  }
  &_suggestion {
    text-align: center;
    &_main {
      /* margin-top: -47px; */
    }
    &_logo {
      height: 50px;
      background: #fff;
      overflow: hidden;
      width: 181px;
      display: inline-block;
      border-radius: 10px;
      border: 1px solid #0dcaf0;
      margin-top: -49px;
      z-index: 1;
      position: relative;
      img {
        height: 100%;
        position: static !important;
        width: auto !important;
        max-width: inherit !important;
        min-width: inherit !important;
      }
    }
    &_profile {
      &_img {
        display: inline-block;
        width: 200px;
        height: 200px;
        overflow: hidden;
        border-radius: 100%;
        border: 5px solid $white;
        position: relative;
        background: #fff;

        img {
          max-height: 100%;
          max-width: 100%;
          position: absolute;
          // transform: translate(7%, -1%);
          // top: 50%;
          //left: 50%;
          width: 100%;
        }
      }
    }
    &_name {
      color: $white;
      font-size: 1.5rem;
      font-weight: 600;
    }
    &_title {
      color: rgba($color: $white, $alpha: 0.5);
    }
    &_desc {
      display: inline-block;
      max-width: 800px;
      color: rgba($color: $white, $alpha: 0.5);
      padding-top: 1rem;
      position: relative;
      &::before {
        content: "";
        width: 50px;
        height: 5px;
        border-radius: 100px;
        background: $white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0.5rem;
      }
    }
  }
}

@include desktop() {
  .slider {
    overflow: hidden;
    /* margin-top: 69px; */
    &_inner {
      padding: 0 30px;
    }
    &_title {
      font-size: 25px;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: $white !important;
  &:after {
    font-size: 2rem !important;
  }
}

.swiper-button-prev {
  left: 30px !important;
}

.swiper-button-next {
  right: 30px !important;
}

.swiper {
  &-pagination {
    &-bullet {
      &-active {
        transform: scale(1);
        background-color: $default_color !important;
      }
    }
  }
  &-slide {
    .slider-news {
      background-color: rgba($color: $default_blue_color, $alpha: 0.1);
    }
    &-active {
      .slider-news {
        background-color: $gray-200;
      }
    }
  }
}

@media (max-width: 767px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
  .swiper {
    &-pagination {
      &-bullet {
        background-color: $white !important;
      }
    }
  }
}

.section-header {
  text-align: center;
  color: #fff;
  margin-bottom: 34px;
  display: inline-block;
  width: 100%;
}

.slider-main {
  height: 80vh;
  padding-bottom: 40px !important;
  @media screen and (max-width: 767px) {
    height: 622px;
  }
  .swiper-slide {
    width: 1230px !important;
    .slider-inner {
      position: absolute;
      bottom: 0;
      padding: 25px;
      background: transparent;
      width: 100%;
      height: 27vh;
      @media screen and (max-width: 767px) {
        height: 28vh;
      }
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
}
