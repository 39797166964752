.accordion {
  &_wrapper {
    margin: 50px 0;
  }
  &_button {
    display: inline-block;
    width: 100% !important;
  }
  &_panel {
    background-color: rgba($color: $black, $alpha: 0.05);
    padding: 15px;
    border-radius: 5px;
    div[role="button"] {
      h3 {
        color: $default_color;
      }
      .title {
        &_content {
          background-color: $black;
        }
      }
    }
  }
}

.accordion {
  &-partners {
    .accordion {
      &-item {
        border: 0;
        button {
          &:focus {
            outline: 0;
            box-shadow: 0 0 0 0 transparent;
          }
          background-color: $white;
          color: $default_color;
          padding-left: 0;
          &.collapsed {
            background-color: $white;
            color: $black;
          }
        }
      }
      &-header {
        border-bottom: 1px solid $gray-400;
      }
      &-body {
        background-color: rgba($color: $default_color, $alpha: 0.05);
      }
    }
  }
}

.menu-mobile {
  .accordion {
    a {
      display: block;
    }
    .accordion {
      &-body {
        padding: 0;
      }
    }
  }
}

.bread-crumb-container {
  border-top: 1px solid #efefef;
  padding: 10px;
  .breadcrumb {
    margin: 0;
    &-item {
      a {
        color: $default_color;
      }
    }
  }
}
