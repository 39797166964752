.loading {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  transform: translate(-50%, -50%);
  &_spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
