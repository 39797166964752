// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$isnet-font-family: "isnet" !default;
$isnet-font-path: "../../fonts" !default;

$icon-close: "\f00d";
$icon-remove: "\f00d";
$icon-times: "\f00d";
$icon-exclamation-circle: "\f06a";
$icon-exclamation-triangle: "\f071";
$icon-warning: "\f071";
$icon-threads: "\e900";
$icon-x: "\e901";
$icon-home: "\e902";
$icon-home2: "\e903";
$icon-home3: "\e904";
$icon-icon-call: "\e905";
$icon-icon-edonusum: "\e906";
$icon-icon-pop: "\e907";
$icon-icon-digital: "\e908";
$icon-search1: "\e909";
$icon-build: "\e90a";
$icon-hands: "\e90b";
$icon-user1: "\e90c";
$icon-chess: "\e90d";
$icon-image: "\e90e";
$icon-images: "\e90f";
$icon-bullhorn: "\e91a";
$icon-file-empty: "\e924";
$icon-files-empty: "\e925";
$icon-copy: "\e92c";
$icon-phone: "\e942";
$icon-location: "\e947";
$icon-display: "\e956";
$icon-laptop: "\e957";
$icon-mobile: "\e958";
$icon-mobile2: "\e959";
$icon-undo: "\e965";
$icon-redo: "\e966";
$icon-undo2: "\e967";
$icon-redo2: "\e968";
$icon-forward: "\e969";
$icon-reply: "\e96a";
$icon-bubble: "\e96b";
$icon-bubbles1: "\e96c";
$icon-bubbles21: "\e96d";
$icon-bubble21: "\e96e";
$icon-bubbles31: "\e96f";
$icon-bubbles41: "\e970";
$icon-bubbles: "\e971";
$icon-bubbles2: "\e972";
$icon-bubble2: "\e973";
$icon-bubbles3: "\e974";
$icon-bubbles4: "\e975";
$icon-user: "\e976";
$icon-quotes-left: "\e977";
$icon-quotes-right: "\e978";
$icon-users: "\e979";
$icon-spinner: "\e97a";
$icon-spinner2: "\e97b";
$icon-spinner3: "\e97c";
$icon-user-plus: "\e97d";
$icon-user-minus: "\e97e";
$icon-user-check: "\e97f";
$icon-user-tie: "\e980";
$icon-cog: "\e994";
$icon-list-numbered1: "\e9b9";
$icon-list1: "\e9ba";
$icon-list21: "\e9bb";
$icon-tree: "\e9bc";
$icon-list-numbered: "\e9bd";
$icon-list: "\e9be";
$icon-list2: "\e9bf";
$icon-sun: "\e9d4";
$icon-blocked: "\ea0e";
$icon-cross: "\ea0f";
$icon-checkmark: "\ea10";
$icon-arrow-up-left2: "\ea39";
$icon-arrow-up2: "\ea3a";
$icon-arrow-up-right2: "\ea3b";
$icon-arrow-right2: "\ea3c";
$icon-arrow-down-right2: "\ea3d";
$icon-arrow-down2: "\ea3e";
$icon-arrow-down-left2: "\ea3f";
$icon-arrow-left2: "\ea40";
$icon-facebook: "\ea90";
$icon-instagram: "\ea92";
$icon-twitter: "\ea96";
$icon-youtube: "\ea9d";
$icon-youtube2: "\ea9e";
$icon-github: "\eab0";
$icon-linkedin2: "\eaca";
$icon-pinterest2: "\ead2";
$icon-libreoffice: "\eae3";
$icon-search: "\f002";
$icon-envelope-o: "\f003";
$icon-star: "\f005";
$icon-star-o: "\f006";
$icon-check: "\f00c";
$icon-search-plus: "\f00e";
$icon-search-minus: "\f010";
$icon-cog1: "\f013";
$icon-edit: "\f044";
$icon-plus: "\f067";
$icon-minus: "\f068";
$icon-linkedin: "\f0e1";
$icon-angle-double-left: "\f100";
$icon-angle-double-right: "\f101";
$icon-angle-double-up: "\f102";
$icon-angle-double-down: "\f103";
$icon-angle-left: "\f104";
$icon-angle-right: "\f105";
$icon-angle-up: "\f106";
$icon-angle-down: "\f107";
$icon-microphone: "\f130";
$icon-moon-o: "\f186";
$icon-fax: "\f1ac";
$icon-paper-plane: "\f1d8";
$icon-paper-plane-o: "\f1d9";

@font-face {
  font-family: "#{$isnet-font-family}";
  src: url("#{$isnet-font-path}/#{$isnet-font-family}.eot?gb2eoe");
  src: url("#{$isnet-font-path}/#{$isnet-font-family}.eot?gb2eoe#iefix")
      format("embedded-opentype"),
    url("#{$isnet-font-path}/#{$isnet-font-family}.ttf?gb2eoe")
      format("truetype"),
    url("#{$isnet-font-path}/#{$isnet-font-family}.woff?gb2eoe") format("woff"),
    url("#{$isnet-font-path}/#{$isnet-font-family}.svg?gb2eoe##{$isnet-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$isnet-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-remove {
  &:before {
    content: $icon-remove;
  }
}
.icon-times {
  &:before {
    content: $icon-times;
  }
}
.icon-exclamation-circle {
  &:before {
    content: $icon-exclamation-circle;
  }
}
.icon-exclamation-triangle {
  &:before {
    content: $icon-exclamation-triangle;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-threads {
  &:before {
    content: $icon-threads;
  }
}
.icon-x {
  &:before {
    content: $icon-x;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-home2 {
  &:before {
    content: $icon-home2;
  }
}
.icon-home3 {
  &:before {
    content: $icon-home3;
  }
}
.icon-icon-call {
  &:before {
    content: $icon-icon-call;
  }
}
.icon-icon-edonusum {
  &:before {
    content: $icon-icon-edonusum;
  }
}
.icon-icon-pop {
  &:before {
    content: $icon-icon-pop;
  }
}
.icon-icon-digital {
  &:before {
    content: $icon-icon-digital;
  }
}
.icon-search1 {
  &:before {
    content: $icon-search1;
  }
}
.icon-build {
  &:before {
    content: $icon-build;
  }
}
.icon-hands {
  &:before {
    content: $icon-hands;
  }
}
.icon-user1 {
  &:before {
    content: $icon-user1;
  }
}
.icon-chess {
  &:before {
    content: $icon-chess;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-images {
  &:before {
    content: $icon-images;
  }
}
.icon-bullhorn {
  &:before {
    content: $icon-bullhorn;
  }
}
.icon-file-empty {
  &:before {
    content: $icon-file-empty;
  }
}
.icon-files-empty {
  &:before {
    content: $icon-files-empty;
  }
}
.icon-copy {
  &:before {
    content: $icon-copy;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-display {
  &:before {
    content: $icon-display;
  }
}
.icon-laptop {
  &:before {
    content: $icon-laptop;
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile;
  }
}
.icon-mobile2 {
  &:before {
    content: $icon-mobile2;
  }
}
.icon-undo {
  &:before {
    content: $icon-undo;
  }
}
.icon-redo {
  &:before {
    content: $icon-redo;
  }
}
.icon-undo2 {
  &:before {
    content: $icon-undo2;
  }
}
.icon-redo2 {
  &:before {
    content: $icon-redo2;
  }
}
.icon-forward {
  &:before {
    content: $icon-forward;
  }
}
.icon-reply {
  &:before {
    content: $icon-reply;
  }
}
.icon-bubble {
  &:before {
    content: $icon-bubble;
  }
}
.icon-bubbles1 {
  &:before {
    content: $icon-bubbles1;
  }
}
.icon-bubbles21 {
  &:before {
    content: $icon-bubbles21;
  }
}
.icon-bubble21 {
  &:before {
    content: $icon-bubble21;
  }
}
.icon-bubbles31 {
  &:before {
    content: $icon-bubbles31;
  }
}
.icon-bubbles41 {
  &:before {
    content: $icon-bubbles41;
  }
}
.icon-bubbles {
  &:before {
    content: $icon-bubbles;
  }
}
.icon-bubbles2 {
  &:before {
    content: $icon-bubbles2;
  }
}
.icon-bubble2 {
  &:before {
    content: $icon-bubble2;
  }
}
.icon-bubbles3 {
  &:before {
    content: $icon-bubbles3;
  }
}
.icon-bubbles4 {
  &:before {
    content: $icon-bubbles4;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-quotes-left {
  &:before {
    content: $icon-quotes-left;
  }
}
.icon-quotes-right {
  &:before {
    content: $icon-quotes-right;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-spinner {
  &:before {
    content: $icon-spinner;
  }
}
.icon-spinner2 {
  &:before {
    content: $icon-spinner2;
  }
}
.icon-spinner3 {
  &:before {
    content: $icon-spinner3;
  }
}
.icon-user-plus {
  &:before {
    content: $icon-user-plus;
  }
}
.icon-user-minus {
  &:before {
    content: $icon-user-minus;
  }
}
.icon-user-check {
  &:before {
    content: $icon-user-check;
  }
}
.icon-user-tie {
  &:before {
    content: $icon-user-tie;
  }
}
.icon-cog {
  &:before {
    content: $icon-cog;
  }
}
.icon-list-numbered1 {
  &:before {
    content: $icon-list-numbered1;
  }
}
.icon-list1 {
  &:before {
    content: $icon-list1;
  }
}
.icon-list21 {
  &:before {
    content: $icon-list21;
  }
}
.icon-tree {
  &:before {
    content: $icon-tree;
  }
}
.icon-list-numbered {
  &:before {
    content: $icon-list-numbered;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-list2 {
  &:before {
    content: $icon-list2;
  }
}
.icon-sun {
  &:before {
    content: $icon-sun;
  }
}
.icon-blocked {
  &:before {
    content: $icon-blocked;
  }
}
.icon-cross {
  &:before {
    content: $icon-cross;
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark;
  }
}
.icon-arrow-up-left2 {
  &:before {
    content: $icon-arrow-up-left2;
  }
}
.icon-arrow-up2 {
  &:before {
    content: $icon-arrow-up2;
  }
}
.icon-arrow-up-right2 {
  &:before {
    content: $icon-arrow-up-right2;
  }
}
.icon-arrow-right2 {
  &:before {
    content: $icon-arrow-right2;
  }
}
.icon-arrow-down-right2 {
  &:before {
    content: $icon-arrow-down-right2;
  }
}
.icon-arrow-down2 {
  &:before {
    content: $icon-arrow-down2;
  }
}
.icon-arrow-down-left2 {
  &:before {
    content: $icon-arrow-down-left2;
  }
}
.icon-arrow-left2 {
  &:before {
    content: $icon-arrow-left2;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-youtube2 {
  &:before {
    content: $icon-youtube2;
  }
}
.icon-github {
  &:before {
    content: $icon-github;
  }
}
.icon-linkedin2 {
  &:before {
    content: $icon-linkedin2;
  }
}
.icon-pinterest2 {
  &:before {
    content: $icon-pinterest2;
  }
}
.icon-libreoffice {
  &:before {
    content: $icon-libreoffice;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-envelope-o {
  &:before {
    content: $icon-envelope-o;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-star-o {
  &:before {
    content: $icon-star-o;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-search-plus {
  &:before {
    content: $icon-search-plus;
  }
}
.icon-search-minus {
  &:before {
    content: $icon-search-minus;
  }
}
.icon-cog1 {
  &:before {
    content: $icon-cog1;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-angle-double-left {
  &:before {
    content: $icon-angle-double-left;
  }
}
.icon-angle-double-right {
  &:before {
    content: $icon-angle-double-right;
  }
}
.icon-angle-double-up {
  &:before {
    content: $icon-angle-double-up;
  }
}
.icon-angle-double-down {
  &:before {
    content: $icon-angle-double-down;
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left;
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right;
  }
}
.icon-angle-up {
  &:before {
    content: $icon-angle-up;
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down;
  }
}
.icon-microphone {
  &:before {
    content: $icon-microphone;
  }
}
.icon-moon-o {
  &:before {
    content: $icon-moon-o;
  }
}
.icon-fax {
  &:before {
    content: $icon-fax;
  }
}
.icon-paper-plane {
  &:before {
    content: $icon-paper-plane;
  }
}
.icon-paper-plane-o {
  &:before {
    content: $icon-paper-plane-o;
  }
}
