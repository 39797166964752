.dark {
  body,
  header.header_scroll {
    background: darken($color: $default_color, $amount: 10);
  }
  a,
  button,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  div {
    color: lighten($color: $default_color, $amount: 55) !important;
  }
  .card {
    background-color: $default_color;
  }
}
