.card {
  border-radius: 0;
  height: 100%;
  &_features {
    box-shadow: 0 0.2rem 0.2rem 0 rgba($color: #d9d9d9, $alpha: 0.1);
    border-radius: 0.5rem;
    position: relative;
    z-index: 2;
    background-color: $white;
    padding: 1rem;
    overflow: hidden;
    // transition: 0.5s ease all;
    text-align: center;
    height: 100%;
    &_icon,
    &_title,
    &_desc {
      transition: 0.5s ease all;
    }
    &_icon {
      font-size: 5rem;
      color: $default_color;
      margin-bottom: 30px;
      height: 170px;
      position: relative;
      img {
        width: 170px;
      }
    }
    &_title {
      font-weight: 700;
      color: $black;
      font-size: 18px;
    }
    &_desc {
      color: $text_gray;
    }
    &_inner {
      position: relative;
      z-index: 1;
    }
    /* &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 0;
      transition: 0.5s ease all;
      background: $default_color;
    }
    &:hover {
      &:before {
        width: 100%;
        transition: 0.5s ease all;
      }
      .card_features_icon,
      .card_features_title,
      .card_features_desc {
        color: $white;
      }
    } */
    &_content {
      padding: 15px;
      &_icon {
        width: 100%;
        border: 1px solid rgba($color: $black, $alpha: 0.05);
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 15px;
        &_i {
          width: 100px;
          height: 50px;
          display: inline-block;
          text-align: center;
        }
        &_details {
          width: calc(100% - 150px);
          display: inline-block;
          h3 {
            margin: 0;
          }
          p {
            margin: 0;
          }
        }
      }
    }
    &_icon_with_link {
      &_body {
        display: inline-block;
        width: 100%;
      }
      &_icon {
        width: 50px;
        height: 50px;
        display: inline-block;
      }
      &_detail {
        width: calc(100% - 55px);
        display: inline-block;
      }
    }
  }
  &_wrapper {
    border: 1px solid $gray-100;
    background-position: center;
    background-repeat: no-repeat;
  }
  &_news {
    background-color: transparent;
    &_left {
      height: 100%;
      background-color: #f1f0f0;
      padding: 15px;
      h4 {
        color: $text_gray;
      }
      &_item {
        border: 0 !important;
        border-bottom: 1px solid rgba($color: $black, $alpha: 0.1) !important;
        display: block;
        padding: 15px 0;
        height: auto !important;
        margin: 0 !important;
        p {
          color: $text_gray;
          margin: 0;
        }
      }
    }
  }
  &_content {
    &_icon {
      margin-bottom: 15px;
      i,
      p,
      a {
        color: $default_color;
        font-weight: 600;
        margin: 0;
      }
    }
  }
  &_more {
    &_news {
      a {
        height: 150px;
        display: inline-block;
        border: 1px solid $gray-100;
        margin-bottom: 15px;
        padding: 15px;
        width: 100%;
        @media only screen and (max-width: 767px) {
          height: auto;
        }
        h3,
        h4,
        h5 {
          color: $black;
        }
      }
    }
  }
}

@include desktop() {
  .card_features {
    margin-top: 0;
    &_icon {
      font-size: 3rem;
      color: $default_color;
    }
    &_title {
      font-size: 20px;
      height: auto;
      margin: 0;
    }
    &_desc {
      margin: 0;
    }
    &_col {
      padding: 0 15px 15px 15px !important;
    }
  }
}

.card {
  &-news {
    border: 0;
    border-radius: 0;
    border-right: 1px solid $gray-300;
    height: 100%;
    background-color: transparent;
    &.middle {
      &:first-child {
        border-bottom: 1px solid #dee2e6;
      }
    }
    &-right {
      .row {
        padding: 0 25px;
      }
      border-bottom: 1px solid $gray-300;
      display: block;
      padding: 13px 0;
    }
  }
  &-title {
    color: $black;
  }
  &-text {
    height: 93px;
    overflow: hidden;
  }
  &-link {
    font-weight: 700;
    color: $default_color;
    border: 1px solid $default_color;
    padding: 5px 20px;
    border-radius: 5px;
    transition: 0.5s ease all;
    &:hover {
      background-color: $default_color;
      color: $white;
    }
  }
  &-news {
    border: 0;
    .card-body {
      padding: 1rem 1rem 1rem 1.5rem;
    }
  }
  &-wrapper {
    .card-news {
      border-bottom: 1px solid #dee2e6;
    }
  }
  &-list {
    border-left: 1px solid #dee2e6;
  }
  &-bluuty {
    border: 0;
    text-align: center;
    margin-bottom: 30px;
    .card-img {
      text-align: center;
      img {
        height: 150px;
      }
    }
    .card-about-title {
      font-weight: 800;
    }
  }
  &-popular {
    position: relative;
    box-shadow: 0px 0px 25px 0px #b9fcb4;
    border: 1px solid #b9fcb4;
  }
  &-satelite {
    text-align: center;
    border: 0;
    margin-bottom: 15px;
    border-radius: 25px;
    overflow: hidden;
    .card-title h2 {
      font-weight: 900;
    }
    .card-top {
      padding: 52px 0;
      overflow: hidden;
      border-radius: 25px 25px 0px 0px;
      h1,
      h2,
      p {
        color: $white;
      }
      .card-desc {
        p {
          font-size: 22px;
        }
      }
      .card-sub {
        p {
          font-size: 22px;
        }
      }
    }
    .card-bottom {
      border-radius: 0px 0px 30px 30px;
      overflow: hidden;
      .card-footer {
        background-color: $white;
        border: 0;
        padding: 15px 0;
        .btn-app {
          background-color: #0333a1;
          color: #fff;
          padding: 10px 23px;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
          font-size: 21px;
          border-radius: 13px;
          margin-top: 15px;
        }
      }
    }
  }
  &-dictionary {
    padding: 10px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 8%);
    margin-bottom: 30px;
    h3 {
      border-bottom: 1px solid #f4f4f4;
      padding: 10px 0;
      font-size: 22px;
      color: #0033a1;
    }
  }
}
.box-satelite .box-title {
  padding: 13px 0;
}
.popular {
  position: absolute;
  right: 0;
  background: #b9fcb4;
  padding: 8px 17px;
  border-radius: 0px 0px 0px 16px;
}
.text-satelite {
  font-weight: 600;
  font-size: 18px;
  height: 78px;
}

.card-money span,
.card-money h1 {
  display: initial;
  color: #000059;
}

.card-money h1 {
  font-weight: 400;
  font-size: 85px;
}

.card-money span {
  font-size: 30px;
  position: relative;
  top: 0;
  bottom: 14px;
  margin-bottom: 39px;
}

.card-price p,
.card-price span {
  font-size: 27px;
  color: #000059;
  margin: 0;
}

.border {
  &-right {
    border-right: 1px solid #dee2e6;
  }
}

.category-title {
  text-align: center;
  background-color: #f1f0f0;
  padding: 10px 0;
  margin-bottom: 30px;
  h3 {
    color: #0033a1;
    font-weight: 800;
    margin: 0;
  }
}

.news-search {
  margin-bottom: 10px;
  margin-bottom: 10px;
  border: 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding: 15px;
  label {
    display: none;
  }
  input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid $gray-300;
    height: 40px;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  &:is(focus, :focus-visible, :focus-within) {
    border: 0;
    outline: 0;
  }
}

.box {
  background-color: $gray-100;
  &-title {
    text-align: center;
    font-weight: 800;
  }
}

.card-product-item {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 21px;
}

.card-product-item-img img {
  height: 49px;
}

.card-product-item-img {
  width: 100%;
  text-align: center;
}

.card-product-item-body {
  text-align: center;
}

.card-product-item-body p {
  height: 156px;
}
.card-product-header {
  text-align: center;
  margin-bottom: 30px;
  color: $default_color;
  font-size: 24px;
}

h3.card-product-title {
  font-size: 24px;
  font-weight: 400;
}
.card-product-footer {
  margin-top: 30px;
}
