@import "bootstrap/scss/bootstrap.scss";
@import "./mixins/mixins";
//Base
@import "./base/color";
@import "./base/fonts";

@import "./shared/header";
@import "./shared/footer";

//Components
@import "./components/buttons";
@import "./components/cards";
@import "./components/accordions";
@import "./components/tabs";
@import "./components/form";
@import "./components/section";
@import "./components/slider";
@import "./components/address";
@import "./components/spinner";
@import "./components//notfound";

a {
  text-decoration: none;
}

.header {
  &_scroll {
    position: fixed !important;
    background-color: $white;
    box-shadow: 0 0.2rem 0.2rem 0 rgba($color: $black, $alpha: 0.05);
    .menu {
      &-item {
        color: $gray-600;
      }
      &-right {
        color: $gray-600;
      }
    }
    .mobile_menu {
      span {
        background-color: $gray-600;
        &::before,
        &:after {
          background-color: $gray-600;
        }
      }
    }
  }
  &_alert {
    background: #000;
    color: #fff;
    text-align: center;
    padding: 6px;
    margin-bottom: 10px;
    h4 {
      font-size: 23px;
      margin: 0;
    }
  }
}
.btns-copy {
  position: absolute;
  right: 12px;
  top: 13px;
  span {
    position: absolute;
    right: 35px;
    font-size: 11px;
    background: #74d870;
    padding: 4px 10px;
    border-radius: 100px;
    color: #247321;
    top: -1px;
    opacity: 0;
    transition: 0.5s ease all;
    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: #74d870;
      right: -2px;
      transform: rotate(45deg);
      top: 6px;
    }
  }
  .check {
    opacity: 0;
    display: none;
  }
  &.active {
    .check {
      position: absolute;
      top: 3px;
      right: 6px;
      background: #fff;
      width: 131px;
      height: 40px;
      opacity: 1;
      display: block;
    }

    .check i {
      position: absolute;
      right: 0;
      top: 1px;
      color: #3bba3e;
      font-size: 23px;
    }
    i {
      color: #247321;
    }
    span {
      opacity: 1;
    }
  }
}

.btn-copy {
  position: relative;
  font-size: 20px;
  display: inline-block;
  padding: 0px 9px;
  color: #0033a1;
}

form {
  width: 100%;
}

.position {
  &-relative {
    position: relative;
    z-index: 1;
  }
}

.container {
  max-width: 1367px;
}

.section {
  &_bg {
    i {
      color: rgba($color: $white, $alpha: 0.1);
      font-size: 15rem;
      position: absolute;
    }
    .left {
      top: 2rem;
      left: 3rem;
    }
    .right {
      bottom: 2rem;
      right: 3rem;
    }
  }
}

.nav-hr {
  .nav {
    &-link {
      opacity: 0.5;
      &.active {
        background-color: #f7f7f7;
        border: 1px solid #d1d1d1;
        opacity: 1;
        border-bottom: 0;
        border-radius: 15px 15px 0px 0px;
        .icon {
          /* background-color: $default_color;
          color: $white; */
        }
      }
    }
  }
}

.page-title {
  text-align: center;
  position: relative;
  h4 {
    color: #0033a1;
    display: inline-block;
    background: #fff;
    padding: 0 18px;
    font-weight: 700;
    margin: 0;
    position: relative;
    z-index: 1;
  }
  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #ccc;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.card-icon {
  text-align: center;
  .icon {
    width: 150px;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 100%;
    position: relative;
    display: inline-block;
    font-size: 10px !important;
    color: #5380c0;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 64px !important;
    }
  }
  .card-title {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
  }
}

.list {
  &-benefits {
    li {
      &:before {
        display: none;
      }
    }
  }
}

.slider {
  &-main {
    .swiper {
      &-slide {
        &-active {
          //width: 1013px !important;
        }
      }
    }
  }
}

.page-bg {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 767px) {
    height: auto;
  }
  &-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    img {
      height: 100%;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      @media screen and (max-width: 767px) {
        position: static;
        transform: translate(0);
        height: auto;
        width: 100%;
      }
    }
  }
  &-inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    left: 10%;
    width: 451px;
    text-align: center;
    h3 {
      color: #fff;
      font-weight: 700;
      font-size: 40px;
    }
  }
}

.react-page-plugins-layout-background {
  height: 480px;
  background-size: contain !important;
  background-position: center !important;
  background-attachment: inherit !important;
  margin-bottom: 100px;
}

.image {
  height: 100px;
  width: auto;
}
.main-content__checkmark {
  font-size: 4.0625rem !important;
  line-height: 1 !important;
  color: #24b663;
}
.main-content__error {
  font-size: 4.0625rem !important;
  line-height: 1 !important;
  color: #dd2020;
}
.wrapper-1 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .wrapper-1 {
    height: auto;
    margin-top: 67px;
  }
}
.wrapper-2 {
  padding: 30px;
  text-align: center;
}
.wrapper-4 {
  padding: 30px;
  text-align: center;
}

.wrapper-4 p {
  margin: 0;
  font-size: 1.3em;
  color: #000;
  letter-spacing: 1px;
}
.wrapper-2 p {
  margin: 0;
  font-size: 1.3em;
  color: #aaa;
  letter-spacing: 1px;
}
.go-home {
  color: #fff;
  background: $default_color;
  border: none;
  padding: 10px 50px;
  margin: 30px 0;
  border-radius: 30px;
  text-transform: capitalize;
}
.footer-like {
  margin-top: auto;
  background: #a976b233;
  padding: 6px;
  text-align: center;
}
.footer-like p {
  margin: 0;
  padding: 4px;
  color: #000;
  letter-spacing: 1px;
}
.footer-like p a {
  text-decoration: none;
  color: #a976b2;
  font-weight: 600;
}

@media (min-width: 600px) {
  .content {
    max-width: 1000px;
    margin: 0 auto;
  }
  .wrapper-1 {
    height: initial;
    max-width: 750px;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 4px 8px 40px 8px rgba(0, 0, 0, 0.079);
    margin-bottom: 100px;
  }
}

.header-mobile-menu .accordion-item {
  border: 0;
  border-bottom: 1px solid #ececec;
}

.header-mobile-menu .accordion-item .accordion-body ul li {
  list-style-type: none;
}

.header-mobile-menu .accordion-item .accordion-body ul {
  padding: 0;
}

.header-mobile-menu .accordion-item .accordion-body ul li a {
  margin: 0;
  width: 100%;
  margin-bottom: -8px;
  display: inline-block;
}

.header-mobile-menu {
  overflow-y: scroll;
}

.header-mobile {
  position: relative;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #fff;
  margin: 0 !important;
  padding: 19px 15px !important;
}

@media only screen and (max-width: 767px) {
  .page-bg-inner h3,
  .page-bg-inner .h3 {
    font-size: 19px;
    width: 100%;
    text-align: center;
    display: inline-block;
  }

  .page-bg-inner {
    width: 100%;
    text-align: center;
    left: 0;
  }

  .nav-hr .nav-link img {
    height: 94%;
  }
  .nav-hr .nav-item {
    width: 25%;
  }

  .nav-hr .nav-link img {
    height: 94%;
  }

  .nav-hr .nav-link .icon {
    width: 100%;
    height: 33px;
  }

  .nav-hr .nav-link p {
    font-size: 7px;
  }
}

@import "./components/dark";

.not-pointer {
  cursor: default;
}

.search-section {
  width: 100%;
  height: 400px;
  background-color: $default_color;
  margin-bottom: 50px;
  text-align: center;
  h1 {
    color: $white;
  }
}

.menu-right {
  .btn {
    color: #6c757d !important;
  }
}

.slider-news-wrapper {
  padding: 0 90px !important;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 0 0px !important;
  }
  .swiper-pagination {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }
  .slider-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    right: 0;
    z-index: 1;

    @media screen and (max-width: 767px) {
      position: relative;
      text-align: center;
    }
    &-prev,
    &-next {
      color: $default_color;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 3.5rem;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        background-color: $default_color;
        width: 30px;
        height: 30px;
        font-size: 18px;
        color: $white;
        border-radius: 100%;
        text-align: center;
        padding: 2px;
        position: static;
        display: inline-block;
        transform: translate(0);
        margin: 0 10px;
      }
    }
    &-prev {
      left: 0;
    }
    &-next {
      right: 0;
    }
  }
  &.suggestion {
    .slider-navigation {
      &-prev,
      &-next {
        color: $white;
      }
    }
  }
}

.nav-justified button {
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0px 20px !important;
  height: 106px;
  border: 1px solid #f0f0f0 !important;
}

.nav-justified button:hover {
  background: #f0f0f0 !important;
}

@media only screen and (max-width: 767px) {
  .section_header_page_blog {
    /* margin-top: 68px; */
    display: inherit;
  }
}

.modal-img .modal-dialog .modal-dialog {
  padding: 0;
  margin: 0;
}

.modal-img .modal-dialog .modal-dialog .modal-body {
  padding: 0;
}

.modal-img .modal-dialog .modal-dialog .modal-body img {
  width: 100%;
}

.modal-img .modal-header {
  padding: 0;
  position: relative;
}

.modal-img .modal-header .btn-close {
  position: absolute;
  right: -24px;
  top: 0;
  color: #fff;
}
.slider_earthquake {
  &:before {
    background: transparent !important;
  }
}

.suggestion {
  .swiper-pagination {
    bottom: -5px !important;
  }
}

.modal-diji {
  .modal-content {
    height: auto !important;
  }
  .modal-dialog {
    max-width: 872px !important;
  }
}
@media only screen and (max-width: 767px) {
  .modal-img .modal-header .btn-close {
    right: 10px;
    z-index: 999;
    top: 10px;
  }
}

.btn-fixed {
  position: fixed;
  z-index: 9;
  background: #0033a1;
  padding: 10px 21px;
  color: #fff;
  transform: rotate(90deg);
  top: 67%;
  right: -60px;
  border-radius: 0px 0px 10px 10px;
  font-weight: 700;
  width: 165px;
  text-align: center;
  &:hover {
    color: #fff;
  }
}

.modal-img .modal-dialog {
  max-width: fit-content;
  height: 100%;
}

.modal-img .modal-dialog img,
.modal-img .modal-dialog a {
  height: 100%;
  position: relative;
  width: 100%;
  display: block;
}

.modal-img .modal-dialog .modal-dialog .modal-body {
  height: 100%;
}

.modal-img .modal-content {
  height: 100%;
}

@media only screen and (max-width: 500px) {
  .modal-img .modal-content {
    height: auto;
  }
}

a.card-certificate {
  height: 291px;
  overflow: hidden;
  position: relative;
}

a.card-certificate img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-next:disabled {
  background: #198754;
  border-color: #198754;
}

.hidden-img {
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
  }
}

.relative-img {
  display: block;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.cookie-banner {
  background-color: $white;
  padding: 30px 0;
  z-index: 99999;
  box-shadow: 0 0 30px 0 rgba($color: $black, $alpha: 0.05);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.cookie-banner .btn-primary {
  background: #00329f;
}

.embla {
  max-width: 90%;
  margin: auto;
  --slide-height: 29rem;
  --slide-spacing: 1rem;
  --slide-size: 70%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__img {
  border-radius: 1.8rem;
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}
