.header {
  z-index: 3;
  position: relative;
  top: 0;
  width: 100%;
  padding: 1.5rem 0;
  background-color: $white;
  &_logo {
    &_mobile {
      width: 100px;
      display: inline-block;
      padding: 0 !important;
      margin-right: 15px;
      margin: 0 !important;
      img {
        width: 100%;
      }
    }
  }
  &_mobile {
    display: none;
    padding: 15px 0px;
    a {
      padding: 10px 15px;
      margin-left: 10px;
      border-radius: 5px;
      color: $black;

      /* font-size: 16px; */
      /* display: block; */
    }
    &_bottom {
      padding: 0 15px;
    }
  }
  &_scroll {
    background-color: $white;
    position: fixed;
    box-shadow: 0 1rem 1rem 0 rgba($color: $white, $alpha: 0.1);
  }
}

.menu {
  margin-left: 0rem;
  button {
    i {
      margin-left: 0.5rem;
    }
  }
  &_right {
    color: $gray-600;
    font-size: 1.5rem;
    margin-left: 1rem;
    display: inline-block;
    &_lang {
      width: 30px;
      height: 30px;
      overflow: hidden;
      display: inline-block;
      border-radius: 100%;
      span {
        position: absolute !important;
        left: 0 !important;
        top: 8px !important;
        border-radius: 100% !important;
        width: 30px !important;
        height: 30px !important;
      }
      img {
        height: 100px !important;
        position: absolute;
        top: 100% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
      }
    }
  }
  &_sub {
    display: none;
    position: absolute;
    &_item {
      position: relative;
      &:hover {
        .menu {
          &_child {
            left: 100%;
            display: block;
            width: 245px;
            background: $white;
            border-radius: 5px;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.11);
            margin: 0;
            top: 0;
            padding: 0;
            list-style-type: none;
            /* max-height: 342px; */
            overflow-y: auto;
            &_item {
              position: relative;
              z-index: 2;
              list-style-type: none;
              padding: 11px 10px;
              border-bottom: 1px solid #f6f6f6;
            }
            a {
              color: $gray-600;
              &:hover {
                color: $default_color;
              }
            }
          }
        }
      }
    }
    &_link {
    }
    .menu {
      &_sub {
        display: none;
        position: absolute;
        left: 100%;

        &_item {
        }
        &_link {
        }
      }
    }
  }
  &_child {
    display: none;
    position: absolute;
    &:before {
      content: "";
      background-color: $white;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 15px;
      left: -5px;
      transform: rotate(45deg);
    }
    &_item {
      color: $gray-600;
    }
    &_link {
      color: $gray-600 !important;
    }
  }
  .item {
    padding: 0.5rem 0.8rem;
    border-radius: 100px;
    color: $gray-600;
    transition: 0.5s ease all;
    display: inline-block;
    position: relative;
    @media screen and (max-width: 1280px) {
      padding: 0.5rem 0.6rem;
    }
    i {
      margin-left: 7px;
    }
    &:hover {
      background: rgba($color: $white, $alpha: 0.1);
      .menu {
        &_sub {
          display: block;
          width: 245px;
          background: $white;
          border-radius: 5px;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.11);
          padding: 15px;
          left: 0;
          margin: 0;
          top: 36px;
          &:before {
            content: "";
            background-color: $white;
            position: absolute;
            width: 20px;
            height: 20px;
            top: -5px;
            left: 30px;
            transform: rotate(45deg);
          }
          &_item {
            display: inline-block;
            width: 100%;
            margin: 0;
          }
          &_link {
            color: $gray-600;
            border-bottom: 1px solid rgba($color: $black, $alpha: 0.05);
            width: 100%;
            display: inline-block;
            padding: 9px 0px;
            &:hover {
              color: $default_color;
            }
          }
        }
      }
    }
  }
  .link {
    color: $gray-600;
    font-weight: 700;
  }
}

.mobile {
  &_menu {
    width: 25px;
    height: 30px;
    position: relative;
    padding: 0 !important;
    float: right;
    span {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $gray-600;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.5s ease all;
      &:before {
        content: "";
        width: 100%;
        height: 2px;
        top: -7px;
        position: absolute;
        background-color: $gray-600;
        transition: 0.5s ease all;
      }
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        top: 7px;
        position: absolute;
        background-color: $gray-600;
        transition: 0.5s ease all;
      }
    }
    &_active {
      span {
        transform: translateY(-50%) rotate(45deg);
        transition: 0.5s ease all;
        &:before {
          transform: rotate(90deg);
          top: -1px;
          transition: 0.5s ease all;
        }
        &:after {
          transition: 0.5s ease all;
          transform: rotate(90deg);
          top: -1px;
          opacity: 0;
        }
      }
    }
    &_content,
    &_child {
      width: 100%;
      background-color: $white;
      position: absolute;
      top: 65px;
      left: 0;
      height: 100vh;
    }
    &_back {
      padding: 15px;
      background-color: rgba($color: $black, $alpha: 0.05);
      width: 100%;
      display: block;
      margin: 0 !important;
      border-radius: 0 0 0 0 !important;
      i {
        margin-right: 15px;
      }
    }
    &_btns {
      margin-top: 15px;
      text-align: center;
      a {
        color: $white;
      }
    }
    &_item {
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
      margin: 0 !important;
    }
  }
  &_search {
    width: 100%;
    background-color: $white;
    position: absolute;
    top: 68px;
    left: 0;
    height: 100vh;
    padding: 15px;
    &_form {
      width: 100%;
      background-color: rgba($color: $black, $alpha: 0.05);
      height: 50px;
      border-radius: 5px;
      position: relative;
    }
    &_input {
      background-color: transparent;
      width: 100%;
      height: 100%;
      padding: 0 15px;
    }
    &_btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }
  }
}
.not_accordion {
  display: inline-block;
  padding: 25px 36px;
  border-bottom: 1px solid #ececec;
  border-radius: 0;
  margin: 0;
  width: 100%;
  margin: 0 !important;
}

.search {
  &_bar {
    height: 83vh;
    position: absolute;
    width: 100%;
    background-color: $default_color;
    top: 0;
    @media screen and (max-width: 767px) {
      height: auto;
      position: static;
      background: transparent;
    }
    z-index: 2;
    &_form {
      width: 100%;
      background-color: rgba($color: $white, $alpha: 0.5);
      height: 75px;
      border-radius: 5px;
      position: relative;
      @media screen and (max-width: 767px) {
        background: #0033a1;
      }
    }
    &_input {
      background-color: transparent;
      width: 100%;
      height: 100%;
      color: $default_color;
      font-size: 1.5rem;
      padding: 15px;
      font-weight: 900;
      border: 0;
      @media screen and (max-width: 767px) {
        color: $white;
        font-weight: 600;
        font-size: 1rem;
      }
      &::placeholder {
        color: $default_color;
        @media screen and (max-width: 767px) {
          color: $white;
        }
      }
      &:focus,
      :focus-visible {
        border: 0;
        outline: 0;
      }
    }
    &_btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
      font-size: 2rem;
      color: $white;
      border: 0;
      background-color: transparent;
    }
  }
}

@include desktop() {
  .header {
    padding: 0 0;
    .container {
      padding: 0;
    }
    &_desktop {
      display: none;
    }
    &_mobile {
      display: inline-block;
      padding: 5px 0px;
      margin-top: 19px;
      width: 100%;
      &_menu {
        &_btns {
          a {
            color: $white !important;
          }
        }
      }
    }
    &_logo {
      width: 200px;
    }
  }
  .menu {
    display: none;
  }
}

.search {
  &_bar {
    height: 83vh;
    position: absolute;
    width: 100%;
    background-color: $default_color;
    top: 0;
    @media screen and (max-width: 767px) {
      height: auto;
      position: static;
      background: transparent;
    }
    z-index: 2;
    &_form {
      width: 100%;
      background-color: rgba($color: $white, $alpha: 0.5);
      height: 75px;
      border-radius: 5px;
      position: relative;
      @media screen and (max-width: 767px) {
        background: #0033a1;
      }
    }
    &_input {
      background-color: transparent;
      width: 100%;
      height: 100%;
      color: $default_color;
      font-size: 1.5rem;
      padding: 15px;
      font-weight: 900;
      border: 0;
      @media screen and (max-width: 767px) {
        color: $white;
        font-weight: 600;
        font-size: 1rem;
      }
      &::placeholder {
        color: $default_color;
        @media screen and (max-width: 767px) {
          color: $white;
        }
      }
      &:focus,
      :focus-visible {
        border: 0;
        outline: 0;
      }
    }
    &_btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
      font-size: 2rem;
      color: $white;
      border: 0;
      background-color: transparent;
    }
  }
}
